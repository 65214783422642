<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import Stat from "@/components/widgets/stat";
import Editor from 'primevue/editor';
import { VMoney } from 'v-money';

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    Stat,
    Editor
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      statData: [
        { type: "line", title: "Items", value: "-", icon: "bx-purchase-tag-alt", },
        { type: "line", title: "Total", value: "R$ -,---", icon: "bx-box", },
      ],

      total: {
        active: 0,
        inactive: 0,
        draft: 0,
      },

      fields: [
        { key: "partner", label: "#" },
        { key: "image", label: "#" },
        { key: "sku", label: "SKU" },
        { key: "name", label: "Nome" },
        { key: "inventory", label: "Estoque" },
      ],
      items: null,

      sortBy: "name",
      sortDesc: false,

      product: {
        modal: false,
        loading: false,

        id: "",
        name: "",
        sku: "",
        description: "",
        weight: "",
        status: "",
      },

      money: {
        decimal: '',
        thousands: '',
        prefix: '',
        suffix: '',
        precision: 0
      },
    };
  },
  directives: { money: VMoney },
  methods: {
    getInventory() {
      this.items = null;

      api
        .get("inventory")
        .then((response) => {
          if (response.data.status == "success") {
            this.total = response.data.total;
            this.items = response.data.list;

            this.statData = [
              { type: "line", title: "Items", value: this.total.items.toString(), icon: "bx-purchase-tag-alt", },
              { type: "line", title: "Total", value: this.$options.filters.currency(this.total.real).toString(), icon: "bx-box", },
            ];
          } else {
            this.total = 0;
            this.items = [];
          }
        })
        .catch((error) => {
          if (error) {
            this.total = 0;
            this.items = [];
          }
        })
    },
    showProduct(item) {
      this.product.id = item.id;
      this.product.name = item.name;
      this.product.sku = item.sku;
      this.product.description = item.description;
      this.product.weight = item.weight;
      this.product.inventory = item.inventory;
      this.product.status = item.status == 'active' ? true : false;

      this.product.modal = false;
      this.product.modal = true;
      this.product.loading = false;
    },
    saveProduct() {
      this.product.loading = true;

      api
        .post("inventory", {
          id: this.product.id,
          sku: this.product.sku,
          description: this.product.description,
          weight: this.product.weight,
          inventory: this.product.inventory,
          status: this.product.status,
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.product.loading = false;
            this.product.modal = false;

            this.$toast.success("O produto foi salvo com sucesso.");
            this.getInventory();
          } else {
            this.product.loading = false;
            this.$toast.error(response.data.message);
          }
        })
        .catch((error) => {
          if (error) {
            this.product.loading = false;
            this.$toast.error("Erro ao salvar produto, tente novamente.");
          }
        })
    }
  },
  mounted() {
    this.getInventory();
  }
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/inventory">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item"><router-link tag="a" to="/inventory">Estoque</router-link></li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between">
      <div>
        <h3>Produtos</h3>
        <p></p>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-6 col-lg-3 col-xl-3">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div v-if="!items" class="card">
      <div class="card-body min-vh-25 d-flex justify-content-center align-items-center">
        <b-spinner variant="default" role="status"></b-spinner>
      </div>
    </div>

    <div class="card" v-else-if="items && items.length == 0">
      <div class="card-body min-vh-25 d-flex justify-content-center align-items-center">
        <div class="text-center">
          <i class="far fa-sad-cry font-size-24"></i><br>
          nenhum produto encontrado
        </div>
      </div>
    </div>

    <div v-else class="card">
      <div class="card-body">
        <div class="table-responsive">
          <b-table :items="items" :fields="fields" class="table-nowrap table-style table-hover table-pointer min-vh-25" head-variant="light" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            @row-clicked="showProduct" responsive>
            <template #cell(image)="row">
              <img :src="row.item.image" class="avatar-md rounded">
            </template>
            <template #cell(price_affiliate)="row">
              {{ row.item.price_affiliate | currency }}
            </template>
            <template #cell(price_franchise)="row">
              {{ row.item.price_franchise | currency }}
            </template>
            <template #cell(status)="row">
              <span v-if="row.item.status == 'inactive'" class="bg-danger rounded py-2 px-3 font-size-12 text-white">INATIVO</span>
              <span v-else-if="row.item.status == 'active'" class="bg-success rounded py-2 px-3 font-size-12 text-white">ATIVO</span>
              <span v-else-if="row.item.status == 'draft'" class="bg-dark rounded py-2 px-3 font-size-12 text-white">RASCUNHO</span>
            </template>
            <template #cell(actions)="row">
              <svg v-if="row" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
                <path d="M12 9a3.02 3.02 0 0 0-3 3c0 1.642 1.358 3 3 3 1.641 0 3-1.358 3-3 0-1.641-1.359-3-3-3z"></path>
                <path
                  d="M12 5c-7.633 0-9.927 6.617-9.948 6.684L1.946 12l.105.316C2.073 12.383 4.367 19 12 19s9.927-6.617 9.948-6.684l.106-.316-.105-.316C21.927 11.617 19.633 5 12 5zm0 12c-5.351 0-7.424-3.846-7.926-5C4.578 10.842 6.652 7 12 7c5.351 0 7.424 3.846 7.926 5-.504 1.158-2.578 5-7.926 5z">
                </path>
              </svg>
            </template>
          </b-table>
        </div>
      </div>
    </div>

    <b-modal v-model="product.modal" modal-class="modal-right" hide-header hide-footer>
      <div class="d-flex justify-content-between mb-5">
        <h4 class="m-0">Editar produto</h4>
        <a class="btn btn-outline-link p-0" v-on:click="product.modal = false">Fechar</a>
      </div>
      <b-form @submit.prevent="saveProduct">
        <h4 class="mb-4">{{ product.name }}</h4>
        <b-form-group label="SKU" label-for="product.sku">
          <b-form-input v-model="product.sku" type="text" autocomplete="off"></b-form-input>
        </b-form-group>
        <b-form-group label="Descrição" label-for="product.description">
          <Editor v-model="product.description" editorStyle="height: 320px">
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
              </span>
            </template>
          </Editor>
        </b-form-group>
        <b-form-group label="Peso – Em Gramas" label-for="product.weight">
          <b-form-input v-model="product.weight" type="number" v-money="money" inputmode="numeric" autocomplete="off"></b-form-input>
        </b-form-group>
        <b-form-group label="Estoque" label-for="product.inventory">
          <b-form-input v-model="product.inventory" type="number" v-money="money" inputmode="numeric" autocomplete="off"></b-form-input>
        </b-form-group>
        <b-form-group label="Status" label-for="product.status">
          <b-form-checkbox v-model="product.status" switch size="lg"></b-form-checkbox>
        </b-form-group>
        <div class="mt-5">
          <button class="btn btn-default btn-lg btn-block text-uppercase" :disabled="this.product.loading">
            Salvar
            <b-spinner v-if="product.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
          </button>
        </div>
      </b-form>
    </b-modal>
  </Layout>
</template>

<style>
.p-editor-container .p-editor-toolbar.ql-snow {
  border-color: #D1D1E3;
}

.p-editor-container .p-editor-content.ql-snow {
  border-color: #D1D1E3;
}

.p-editor-container .p-editor-toolbar {
  background: #fff;
}

.p-editor-content .ql-editor {
  font-family: Poppins;
  font-size: 15px;
  color: #131313;
}

.p-editor-content .ql-editor strong {
  font-weight: bold;
}

.p-editor-content .ql-editor p {
  margin-bottom: 15px;
}

.p-editor-content .ql-editor ul {
  margin-left: 0px;
  margin-bottom: 15px;
}

.p-editor-content .ql-editor ul li {
  padding-left: 0px;
  line-height: 25px;
}
</style>